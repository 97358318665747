import acb from '../static/images/acb.jpg';
import aipc from '../static/images/aipc.png';
import ausae from '../static/images/au-saue.png';
import beia from '../static/images/beia.svg';
import bnh from '../static/images/bnh.jpg';
import chamber from '../static/images/chamber-logo.jpeg';
import icca from '../static/images/icca.png';
import pureNewZealand from '../static/images/pure-new-zealand.png';

export const supporters = [
];

export const partners = [
    {
        logo: acb,
        name: 'Auckland Convention Bureau',
        link: 'https://www.aucklandnz.com'
    },
    {
        logo: beia,
        name: 'Business Events Industry Aotearoa',
        link: 'https://beia.co.nz/',
    },
    {
        logo: pureNewZealand,
        name: '100% Pure New Zealand',
        link: 'https://businessevents.newzealand.com/en/'
    },
    // unsure if membership will be renewed so not including for now
    // {
    //     logo: chamber,
    //     name: 'Auckland Business Chamber',
    //     link: 'https://aucklandchamber.co.nz/'
    // },
    {
        logo: ausae,
        name: 'The Australasian Society of Association Executives',
        link: 'https://ausae.org.au/'
    },
    {
        logo: aipc,
        name: 'International Association of Convention Centres ',
        link: 'http://www.aipc.org/',
    },
    {
        logo: icca,
        name: 'International Congress and Convention Association',
        link: 'https://www.iccaworld.org/',
    },
    {
        logo: bnh,
        name: 'Business North Harbour',
        link: 'http://businessnh.org.nz/'
    },
];
