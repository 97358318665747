import { clientEnv } from 'config/env';

export const NAVIGATION_ITEM_TYPE__BUTTON_PRIMARY = 'NAVIGATION_ITEM_TYPE__BUTTON_PRIMARY';

const VENUES = {
    label: 'Venues',
    link: '/venues',
    children: [
        {
            label: 'Aotea Centre',
            link: '/venues/aotea-centre'
        },
        {
            label: 'Auckland Art Gallery',
            link: '/venues/auckland-art-gallery'
        },
        {
            label: 'Auckland Town Hall',
            link: '/venues/auckland-town-hall'
        },
        {
            label: 'Auckland Zoo',
            link: '/venues/auckland-zoo'
        },
        {
            label: 'Bruce Mason Centre',
            link: '/venues/bruce-mason-centre'
        },
        {
            label: 'Go Media Stadium',
            link: '/venues/go-media-stadium'
        },
        {
            label: 'New Zealand Maritime Museum',
            link: '/venues/new-zealand-maritime-museum'
        },
        {
            label: 'North Harbour Stadium',
            link: '/venues/north-harbour-stadium'
        },
        {
            label: 'Shed 10',
            link: '/venues/shed-10'
        },
        {
            label: 'The Civic',
            link: '/venues/the-civic'
        },
        {
            label: 'The Cloud',
            link: '/venues/the-cloud'
        },
        {
            label: 'Viaduct Events Centre',
            link: '/venues/viaduct-events-centre'
        },
        {
            label: 'MOTAT',
            link: '/venues/motat'
        },
        {
            type: NAVIGATION_ITEM_TYPE__BUTTON_PRIMARY,
            label: 'View capacity chart',
            link: '/capacities'
        }
    ]
};

const EVENT_TYPES = {
    label: 'Event Types',
    link: '/event-types',
    children: [
        {
            label: 'Conference',
            link: '/event-types/conference'
        },
        {
            label: 'Exhibition',
            link: '/event-types/exhibition'
        },
        {
            label: 'Meeting',
            link: '/event-types/meeting'
        },
        {
            label: 'Gala dinner',
            link: '/event-types/gala-dinner'
        },
        {
            label: 'Cocktail function',
            link: '/event-types/cocktail-function'
        },
        {
            label: 'School ball',
            link: '/event-types/school-ball'
        },
        {
            label: 'Christmas party',
            link: '/event-types/christmas-party'
        },
        {
            label: 'Wedding',
            link: '/event-types/wedding'
        },
        {
            label: 'VIP show hosting',
            link: '/event-types/vip-show-hosting'
        },
    ]
};

const SERVICES = {
    label: 'Planning',
    link: '/services',
    children: [
        {
            label: 'Catering',
            link: '/services/catering'
        },
        {
            label: 'Event coordination',
            link: '/services/event-coordination'
        },
        {
            label: 'Technical services',
            link: '/services/technical'
        },
        {
            label: 'FAQ',
            link: '/about-us/faq'
        },
    ]
};

const ABOUT_US = {
    label: 'About Us',
    link: '/about-us',
    children: [
        {
            label: 'News',
            link: '/news'
        },
        {
            label: 'Our people',
            link: '/about-us/our-people'
        },
        {
            label: 'Careers',
            link: '/about-us/careers'
        },
        {
            label: 'Sustainability',
            link: '/about-us/sustainability'
        },
        {
            label: 'Visitor information',
            link: '/about-us/visitor-info'
        }
    ]
};

export const NAVIGATION_FOOTER = [
    VENUES,
    EVENT_TYPES
];

export const NAVIGATION_HEADER = [
    VENUES,
    EVENT_TYPES,
    SERVICES,
    ABOUT_US
];

export const HEADER_CTA = {
    label: 'CONTACT',
    link: '/contact',
    trackingAttributes: {
        'data-id': 'micro_conversion',
        'data-type': 'lead',
        'data-action': 'enquire_now_button_click',
        'data-text': 'Enquire now'
    }
};
